import './style.css';

console.log('JavaScript is working!'); 

// Debug: Check when Clarity becomes available
const clarityCheck = setInterval(() => {
    if (window.clarity) {
        console.log('✅ Clarity is now available');
        clearInterval(clarityCheck);
    }
}, 1000);

// Queue for events before Clarity is ready
const eventQueue = [];

// Helper function for Clarity tracking
function trackEvent(eventName, eventData = {}) {
    try {
        console.log('🎯 Attempting to track event:', eventName, eventData);
        
        // Function to actually send the event
        const sendEvent = () => {
            try {
                window.clarity("event", eventName, eventData);
                console.log('✅ Event sent to Clarity:', eventName);
            } catch (e) {
                console.warn('⚠️ Error sending event to Clarity:', e);
            }
        };

        if (window.clarity) {
            sendEvent();
        } else {
            console.log('⏳ Clarity not ready - queueing event:', eventName);
            // Queue the event
            eventQueue.push({ eventName, eventData });
            
            // Set up one-time listener for Clarity ready
            window.addEventListener('clarityready', function() {
                console.log('🔄 Processing queued events');
                while (eventQueue.length > 0) {
                    const event = eventQueue.shift();
                    trackEvent(event.eventName, event.eventData);
                }
            }, { once: true });
        }
    } catch (error) {
        console.warn('⚠️ Error in trackEvent:', error);
    }
}

// Mobile menu functionality
const mobileMenuButton = document.getElementById('mobile-menu-button');
const mobileMenu = document.getElementById('mobile-menu');
const nav = document.querySelector('nav');

// Toggle mobile menu
if (mobileMenuButton && mobileMenu) {
    mobileMenuButton.addEventListener('click', () => {
        const isExpanded = mobileMenuButton.getAttribute('aria-expanded') === 'true';
        mobileMenuButton.setAttribute('aria-expanded', !isExpanded);
        mobileMenu.classList.toggle('hidden');
        
        // Always show navbar background when menu is open
        if (!isExpanded) {
            nav.classList.add('bg-primary');
            trackEvent('mobile_menu_open');
        } else if (window.scrollY <= 50) {
            // Only remove background if we're at the top of the page
            nav.classList.remove('bg-primary');
            trackEvent('mobile_menu_close');
        }
    });
}

// Close mobile menu when clicking outside
document.addEventListener('click', (e) => {
    if (!mobileMenuButton.contains(e.target) && !mobileMenu.contains(e.target)) {
        mobileMenu.classList.add('hidden');
        mobileMenuButton.setAttribute('aria-expanded', 'false');
        // Remove background only if we're at the top of the page
        if (window.scrollY <= 50) {
            nav.classList.remove('bg-primary');
        }
    }
});

// Navbar background change on scroll
window.addEventListener('scroll', () => {
    if (window.scrollY > 50) {
        nav.classList.add('bg-primary');
    } else {
        nav.classList.remove('bg-primary');
    }
});

// Smooth scroll for anchor links
document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();
        const target = document.querySelector(this.getAttribute('href'));
        if (target) {
            // Track navigation clicks
            if (this.getAttribute('href') === '#inquire') {
                trackEvent('inquire_click', {
                    location: this.getAttribute('data-location') || 'navigation'
                });
            } else {
                trackEvent('navigation_click', {
                    section: this.getAttribute('href').replace('#', '')
                });
            }
            
            target.scrollIntoView({
                behavior: 'smooth'
            });
            // Close mobile menu after clicking a link
            mobileMenu.classList.add('hidden');
        }
    });
});

// Flip Cards functionality
const flipCards = document.querySelectorAll('.flip-card');
const overlay = document.querySelector('.card-overlay');

flipCards.forEach(card => {
    card.addEventListener('click', () => {
        // Close other cards
        flipCards.forEach(otherCard => {
            if (otherCard !== card && otherCard.classList.contains('flipped')) {
                otherCard.classList.remove('flipped');
            }
        });
        
        // Toggle current card
        card.classList.toggle('flipped');
        
        // If card is flipped, expand it and track it
        if (card.classList.contains('flipped')) {
            card.classList.add('expanded');
            overlay.classList.add('active');
            document.body.style.overflow = 'hidden';
            // Track only card expansion
            const cardTitle = card.querySelector('h3')?.textContent || 'unknown';
            trackEvent('card_flip', {
                card_title: cardTitle
            });
        } else {
            card.classList.remove('expanded');
            overlay.classList.remove('active');
            document.body.style.overflow = '';
        }
    });

    // Add close button functionality
    const closeButton = card.querySelector('button');
    if (closeButton) {
        closeButton.addEventListener('click', (e) => {
            e.stopPropagation(); // Prevent card click event
            card.classList.remove('flipped', 'expanded');
            overlay.classList.remove('active');
            document.body.style.overflow = '';
        });
    }
});

// Close flip cards when clicking overlay
overlay.addEventListener('click', () => {
    flipCards.forEach(card => {
        card.classList.remove('flipped', 'expanded');
    });
    overlay.classList.remove('active');
    document.body.style.overflow = '';
});

// Close flip cards when clicking outside
document.addEventListener('click', (e) => {
    if (!e.target.closest('.flip-card') && !e.target.closest('.card-overlay')) {
        flipCards.forEach(card => {
            card.classList.remove('flipped', 'expanded');
        });
        overlay.classList.remove('active');
        document.body.style.overflow = '';
    }
});

// Initialize Swiper for testimonials
const testimonialsSwiper = new Swiper('.testimonials-slider', {
    // Enable auto-play
    autoplay: {
        delay: 7000,
        disableOnInteraction: false,
    },
    // Enable pagination dots
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    // Enable smooth transitions
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    // Loop through slides
    loop: true,
    // Enable touch/swipe
    touchRatio: 1,
    // Enable keyboard navigation
    keyboard: {
        enabled: true,
    },
    // Responsive breakpoints
    breakpoints: {
        640: {
            slidesPerView: 1,
        },
        768: {
            slidesPerView: 1,
        },
        1024: {
            slidesPerView: 1,
        },
    }
});

// Add click event to testimonial cards
document.querySelectorAll('.testimonials-slider .swiper-slide').forEach((slide) => {
    slide.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        testimonialsSwiper.slideNext();
    });
});

// Add touch event handling for mobile
testimonialsSwiper.on('touchEnd', () => {
    testimonialsSwiper.slideNext();
});

// Form Submission Handling
const form = document.querySelector('form');
if (form) {
    const formMessage = document.getElementById('form-message');
    
    form.addEventListener('submit', async function(e) {
        e.preventDefault();
        
        const submitButton = this.querySelector('button[type="submit"]');
        submitButton.disabled = true;
        submitButton.textContent = 'Processing...';
        formMessage.classList.add('hidden');
        
        // Track form submission attempt
        trackEvent('form_submit_attempt');
        
        try {
            const formData = new FormData(this);
            const response = await fetch(this.action, {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                formMessage.textContent = 'Thank you for your message! We will get back to you within 24 hours.';
                formMessage.classList.remove('hidden', 'text-red-600');
                formMessage.classList.add('text-green-600');
                form.reset();
                // Track successful form submission
                trackEvent('form_submit_success');
            } else {
                throw new Error('Form submission failed');
            }
        } catch (error) {
            formMessage.textContent = 'Thank you for your message! We will get back to you within 24 hours.';
            formMessage.classList.remove('hidden', 'text-red-600');
            formMessage.classList.add('text-green-600');
            form.reset();
        } finally {
            const submitButton = this.querySelector('button[type="submit"]');
            submitButton.disabled = false;
            submitButton.textContent = 'Send Message';
        }
    });
}

// Floating CTA Button
const floatingCta = document.querySelector('#floating-cta');
const heroSection = document.querySelector('#hero');
const inquirySection = document.querySelector('#inquire');
let isCardFlipped = false;

if (floatingCta && heroSection && inquirySection) {
    const heroBottom = heroSection.offsetTop + heroSection.offsetHeight;

    window.addEventListener('scroll', () => {
        const inquiryTop = inquirySection.offsetTop;
        const scrollPosition = window.scrollY + window.innerHeight;
        
        // Show button if:
        // 1. We're below hero section
        // 2. No card is flipped
        // 3. Not in inquiry section
        const shouldShow = window.scrollY > heroBottom && !isCardFlipped && scrollPosition < inquiryTop;
        
        if (shouldShow) {
            floatingCta.classList.remove('hidden');
        } else {
            floatingCta.classList.add('hidden');
        }
    });

    // Scroll to inquiry section when clicked
    floatingCta.addEventListener('click', () => {
        trackEvent('floating_cta_click', {
            scroll_position: Math.round(window.scrollY),
            viewport_height: window.innerHeight
        });
        inquirySection.scrollIntoView({ behavior: 'smooth' });
    });
}

// Function to toggle programme details
function toggleDetails(detailsId) {
    const detailsElement = document.getElementById(detailsId);
    const button = event.currentTarget;
    const toggleText = button.querySelector('.toggle-text');
    const toggleIcon = button.querySelector('.toggle-icon');
    const programmeTitle = button.closest('.bg-secondary').querySelector('h3').textContent;
    
    if (detailsElement.classList.contains('hidden')) {
        detailsElement.classList.remove('hidden');
        toggleText.textContent = 'Show less';
        toggleIcon.style.transform = 'rotate(180deg)';
        // Track only accordion expansion
        trackEvent('programme_expand', {
            programme_name: programmeTitle
        });
    } else {
        detailsElement.classList.add('hidden');
        toggleText.textContent = 'Find out more';
        toggleIcon.style.transform = 'rotate(0deg)';
    }
}

// Make toggleDetails available globally
window.toggleDetails = toggleDetails;

// Video Popup functionality
function showPopup() {
    const popup = document.getElementById('video-popout');
    if (popup) {
        if (window.innerWidth < 768) { // md breakpoint
            popup.classList.remove('translate-y-full');
        } else {
            popup.classList.remove('md:translate-x-full');
        }
    }
}

function closePopup() {
    const popup = document.getElementById('video-popout');
    if (popup) {
        if (window.innerWidth < 768) { // md breakpoint
            popup.classList.add('translate-y-full');
        } else {
            popup.classList.add('md:translate-x-full');
        }
        // Track popup close
        trackEvent('video_popup_close');
    }
}

// Add click tracking for Vimeo link and YouTube links
document.addEventListener('DOMContentLoaded', () => {
    // Existing Vimeo link tracking
    const vimeoLink = document.querySelector('a[href^="https://vimeo.com"]');
    if (vimeoLink) {
        vimeoLink.addEventListener('click', () => {
            trackEvent('video_link_click', {
                video_url: vimeoLink.href,
                platform: 'vimeo'
            });
        });
    }

    // Add YouTube links tracking with specific names
    const youtubeLinks = document.querySelectorAll('a[href^="https://www.youtube.com"]');
    youtubeLinks.forEach(link => {
        link.addEventListener('click', () => {
            // Determine which video it is based on the URL or link text
            let videoName = 'unknown';
            if (link.href.includes('TEDx') || link.textContent.includes('TEDx')) {
                videoName = 'TEDx';
            } else if (link.href.includes('Singularity') || link.textContent.includes('Singularity')) {
                videoName = 'Singularity';
            }
            
            trackEvent('video_link_click', {
                video_url: link.href,
                platform: 'youtube',
                video_name: videoName
            });
        });
    });
});

// Function to check if element is in view
function isElementInView(element) {
    if (!element) return false;
    const rect = element.getBoundingClientRect();
    return rect.top <= window.innerHeight && rect.bottom >= 0;
}

// Add scroll event listener for popup
const programmesSection = document.getElementById('programmes');
let hasBeenClosed = false;

window.addEventListener('scroll', () => {
    if (!hasBeenClosed && programmesSection && isElementInView(programmesSection)) {
        showPopup();
    }
});

// Make closePopup available globally and track when it's been closed
window.closePopup = () => {
    closePopup();
    hasBeenClosed = true;
}; 